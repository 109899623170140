<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>

    <b-card class="mb-0" no-body>
      <b-card-header class="d-block">
        <b-card-title>
          {{ lang("t_keywordCategories") }}
        </b-card-title>
        <b-card-sub-title class="mt-1">
          {{ lang("t_keywordCategories") }}
        </b-card-sub-title>
      </b-card-header>

      <b-card-body class="px-0">
        <b-card-text class="d-flex mx-2">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>

            <b-form-input
              v-model="searchQuery"
              :placeholder="lang('t_search')"
            ></b-form-input>
          </b-input-group>

          <b-button
            @click="
              modal_record_details = true;
              isEdit = false;
              reset_record();
            "
            size="sm"
            class="px-50 py-50 ml-2"
            variant="flat-secondary"
          >
            <b-icon icon="plus"></b-icon>
          </b-button>
          <b-button size="sm" class="px-50 py-50" variant="flat-secondary">
            <b-icon icon="funnel"></b-icon>
          </b-button>
        </b-card-text>
        <b-table
          :filter="searchQuery"
          small
          hover
          :fields="tableColumns"
          :items="items"
          responsive
          show-empty
          :empty-text="lang('t_noRecord')"
          :busy="records.length == 0"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template #empty="scope">
            <b-container class="mt-5">
              <b-row>
                <b-col class="text-center text-muted mt-50">
                  <span>{{ scope.emptyText }}</span>
                </b-col>
              </b-row>
            </b-container>
          </template>

          <template #cell(is_mail_send_to_agent)="data">
            <feather-icon
              :icon="data.item.is_mail_send_to_agent ? 'CheckIcon' : 'XIcon'"
              size="12"
              class="align-middle text-body"
            />
          </template>
          <template #cell(show_in_dashboard)="data">
            <feather-icon
              :icon="data.item.show_in_dashboard ? 'CheckIcon' : 'XIcon'"
              size="12"
              class="align-middle text-body"
            />
          </template>

          <template #cell(active)="data">
            <feather-icon
              :icon="data.item.active ? 'CheckIcon' : 'XIcon'"
              size="12"
              class="align-middle text-body"
            />
          </template>

          <template #cell(notification)="data">
            <feather-icon
              :icon="data.item.notification ? 'CheckIcon' : 'XIcon'"
              size="12"
              class="align-middle text-body"
            />
          </template>

          <template #cell(speech_text)="data">
            <feather-icon
              :icon="data.item.speech_text ? 'CheckIcon' : 'XIcon'"
              size="12"
              class="align-middle text-body"
            />
          </template>

          <template #cell(audio_file)="data">
            <feather-icon
              :icon="data.item.audio_file ? 'CheckIcon' : 'XIcon'"
              size="12"
              class="align-middle text-body"
            />
          </template>
          <template #cell(rules)="data">
            {{ data.value.map((e) => e.title) }}
          </template>

          <template #cell(actions)="data">
            <b-dropdown
              boundary="viewport"
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="updateSelected(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
              </b-dropdown-item>

              <b-dropdown-item @click="confirmText(data.item)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ lang("t_delete") }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-card-body>

      <b-card-footer class="py-75 border-0">
        <div class="d-flex justify-content-between d-flex align-items-center">
          <div class="d-flex align-items-center mb-0">
            <!-- <span class="text-nowrap"> Showing 1 to </span> -->
            <b-form-select
              size="sm"
              v-model="perPage"
              :options="['10', '25', '50']"
              class="mr-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap text-muted font-small-3">
              {{
                lang("t_total") +
                " " +
                records.length +
                " " +
                lang("t_row").toLowerCase()
              }}
            </span>
          </div>

          <b-pagination
            size="sm"
            class="mb-0"
            v-model="currentPage"
            :per-page="perPage"
            align="right"
            :total-rows="records.length"
            first-number
            last-number
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="16" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="16" />
            </template>
          </b-pagination>
        </div>
      </b-card-footer>
    </b-card>

    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      :title="isEdit ? lang('t_edit') : lang('t_add')"
      centered
      v-model="modal_record_details"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="add_record"
      size="lg"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="display_name"
            rules="required"
          >
            <b-row class="mb-1">
              <b-col>
                <b-form-input
                  id="displayName"
                  v-model="currentData.display_name"
                  trim
                  :placeholder="lang('t_displayName')"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col>
                <flat-pickr
                  :placeholder="lang('t_startDate')"
                  v-model="currentData.start_date"
                  class="form-control"
                  :config="{
                    enableTime: true,
                    dateFormat: 'Y-m-d H:i',
                    allowInput: true,
                    time_24hr: true,
                  }"
                />
              </b-col>
              <b-col>
                <flat-pickr
                  :placeholder="lang('t_endDate')"
                  v-model="currentData.end_date"
                  class="form-control"
                  :config="{
                    dateFormat: 'Y-m-d H:i',
                    enableTime: true,
                    allowInput: true,
                    time_24hr: true,
                  }"
                />
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col>
                <v-select
                  multiple
                  v-model="selected_queue_group"
                  :options="queue_groups"
                  :placeholder="lang('t_queueGroup')"
                  :reduce="(val) => val"
                  value="internal_name"
                  label="display_name"
                  @input="queue_group_changed"
                ></v-select>
              </b-col>
              <b-col>
                <v-select
                  multiple
                  v-model="currentData.queue"
                  :options="queues"
                  :placeholder="lang('t_queue')"
                  :reduce="(val) => val.internal_name"
                  value="internal_name"
                  label="display_name"
                ></v-select>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col>
                <v-select
                  multiple
                  v-model="currentData.agent"
                  :options="agents"
                  :reduce="(val) => val.username"
                  value="username"
                  label="username"
                  :placeholder="lang('t_agent')"
                ></v-select>
              </b-col>
              <b-col>
                <v-select
                  multiple
                  v-model="currentData.who_hangup"
                  :options="[
                    { internal_name: 0, display_name: lang('t_customer') },
                    { internal_name: 1, display_name: lang('t_agent') },
                  ]"
                  :reduce="(val) => val.internal_name"
                  label="display_name"
                  :placeholder="lang('t_rTerminateSide')"
                ></v-select>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col>
                <v-select
                  multiple
                  v-model="currentData.direction"
                  :options="['inbound', 'outbound', 'dialer']"
                  :placeholder="lang('t_direction')"
                ></v-select>
              </b-col>
              <b-col>
                <v-select
                  multiple
                  v-model="currentData.verdict"
                  :options="finish_codes"
                  :placeholder="lang('t_finishCode')"
                  :reduce="(val) => val.finish_code"
                  value="finish_code"
                  label="finish_code"
                ></v-select>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="6">
                <b-form-input
                  type="number"
                  v-model="currentData.min_duration"
                  trim
                  :placeholder="lang('t_minDuration')"
                />
              </b-col>
              <b-col cols="6">
                <b-form-input
                  type="number"
                  v-model="currentData.max_duration"
                  trim
                  :placeholder="lang('t_maxDuration')"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  :description="lang('t_enterEmailsSeparatedByCommas')"
                >
                  <b-form-input
                    v-model="currentData.to"
                    trim
                    :placeholder="lang('t_to')"
                  />
                </b-form-group>
              </b-col>

              <b-col>
                <v-select
                  v-model="currentData.mail"
                  :options="email_settings"
                  :placeholder="lang('t_account')"
                  :reduce="(val) => val"
                  label="display_name"
                ></v-select>
              </b-col>
            </b-row>

            <b-row class="mb-50">
              <b-col class="d-flex align-items-center justify-content-start">
                <b-form-checkbox
                  @input="() => {}"
                  v-model="currentData.is_mail_send_to_agent"
                  class="custom-control-primary"
                >
                  <span class="vs-checkbox">
                    <span class="vs-checkbox--check">
                      <!-- <i class="vs-icon feather icon-check" /> -->
                      {{ lang("t_isMailSendToAgent") }}
                    </span>
                  </span>
                </b-form-checkbox>
              </b-col>
              <b-col class="d-flex align-items-center justify-content-start">
                <b-form-checkbox
                  @input="() => {}"
                  v-model="currentData.show_in_dashboard"
                  class="custom-control-primary"
                >
                  <span class="vs-checkbox">
                    <span class="vs-checkbox--check">
                      <!-- <i class="vs-icon feather icon-check" /> -->
                      {{ lang("t_showInDashboard") }}
                    </span>
                  </span>
                </b-form-checkbox>
              </b-col>
              <b-col class="d-flex align-items-center justify-content-start">
                <b-form-checkbox
                  @input="() => {}"
                  v-model="currentData.active"
                  class="custom-control-primary"
                >
                  <span class="vs-checkbox">
                    <span class="vs-checkbox--check">
                      <!-- <i class="vs-icon feather icon-check" /> -->
                      {{ lang("t_active") }}
                    </span>
                  </span>
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row class="mb-50">
              <b-col class="d-flex align-items-center justify-content-start">
                <b-form-checkbox
                  @input="() => {}"
                  v-model="currentData.notification"
                  class="custom-control-primary"
                >
                  <span class="vs-checkbox">
                    <span class="vs-checkbox--check">
                      <!-- <i class="vs-icon feather icon-check" /> -->
                      {{ lang("t_notification") }}
                    </span>
                  </span>
                </b-form-checkbox>
              </b-col>
              <b-col class="d-flex align-items-center justify-content-start">
                <b-form-checkbox
                  @input="() => {}"
                  v-model="currentData.audio_file"
                  class="custom-control-primary"
                >
                  <span class="vs-checkbox">
                    <span class="vs-checkbox--check">
                      <!-- <i class="vs-icon feather icon-check" /> -->
                      {{ lang("t_audioFile") }}
                    </span>
                  </span>
                </b-form-checkbox>
              </b-col>
              <b-col class="d-flex align-items-center justify-content-start">
                <b-form-checkbox
                  @input="() => {}"
                  v-model="currentData.speech_text"
                  class="custom-control-primary"
                >
                  <span class="vs-checkbox">
                    <span class="vs-checkbox--check">
                      <!-- <i class="vs-icon feather icon-check" /> -->
                      {{ lang("t_speechText") }}
                    </span>
                  </span>
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row align-h="center" class="mb-50">
              <b-col cols="4">
                <v-select
                  v-model="currentData.logic_condition"
                  :options="logic_conditions"
                  :placeholder="lang('t_logicCondition')"
                  :reduce="(val) => val.internal_name"
                  value="internal_name"
                  label="display_name"
                ></v-select>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h5>Kurallar</h5>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-badge
                  v-for="(item, i) in currentData.rules"
                  :key="i"
                  @dblclick="update_rule(item)"
                  href="#"
                  class="mr-50"
                  variant="light-secondary"
                  >{{ `${item.title}` }}</b-badge
                >
                <b-button
                  @click="add_rule"
                  size="sm"
                  variant="flat-success"
                  class="btn-icon"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </b-col>
            </b-row>
          </validation-provider>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      :title="lang('t_rule')"
      centered
      v-model="modal_rules"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_delete')"
      cancel-variant="danger"
      @cancel="delete_rule"
      @ok="save_rule"
      size="xl"
    >
      <b-row class="mb-1">
        <b-col cols="3">
          <b-form-input
            id="displayName"
            v-model="selected_rule.title"
            trim
            :placeholder="lang('t_displayName')"
          />
        </b-col>
        <b-col cols="2">
          <b-form-input
            type="number"
            v-model="selected_rule.min_true_count"
            trim
            :placeholder="lang('t_minTrueCount')"
          />
        </b-col>
        <b-col
          class="d-flex align-items-center justify-content-center"
          cols="1"
        >
          <b-form-checkbox v-model="selected_rule.in_order">{{
            lang("t_ordered")
          }}</b-form-checkbox>
        </b-col>

        <b-col cols="4">
          <v-select
            v-model="selected_speech_rule"
            :options="speech_rules_items"
            :placeholder="lang('t_speechRule')"
            :reduce="(val) => val"
            value="internal_name"
            label="display_name"
          ></v-select>
        </b-col>
        <b-col cols="2">
          <b-button block @click="add_speech_rule" variant="outline-primary">
            <feather-icon icon="PlusIcon"></feather-icon>
            {{ lang("t_addSpeechRule") }}</b-button
          >
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col
          v-for="(item, i) in selected_rule.speech_rules"
          :key="i"
          cols="6"
        >
          <b-row class="mb-1 pb-1 border-bottom">
            <b-col
              cols="4"
              class="d-flex align-items-center justify-content-start"
            >
              <b-badge variant="light-secondary">{{
                item.display_name
              }}</b-badge>
            </b-col>

            <b-col cols="3">
              <b-form-input
                v-model="item.min_value"
                type="number"
                :placeholder="lang('t_minValue')"
              />
            </b-col>
            <b-col cols="3">
              <b-form-input
                v-model="item.max_value"
                type="number"
                :placeholder="lang('t_maxValue')"
              />
            </b-col>
            <b-col cols="2">
              <b-button
                @click="clear_speech_rule(item)"
                variant="flat-secondary"
              >
                <feather-icon size="16" icon="DeleteIcon"></feather-icon>
              </b-button>
              <b-button @click="delete_speech_rule(item)" variant="flat-danger">
                <feather-icon size="16" icon="TrashIcon"></feather-icon>
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mb-1 border-bottom pb-1" align-h="center">
        <b-col cols="1">
          <v-select
            v-model="selected_rule.logic_condition"
            :options="logic_conditions"
            :placeholder="lang('t_logicCondition')"
            :reduce="(val) => val.internal_name"
            value="internal_name"
            label="display_name"
          ></v-select>
        </b-col>
      </b-row>

      <b-row class="mb-1 pb-1 border-bottom">
        <b-col>
          <span>
            {{ lang("t_keyWord") }}
          </span>
        </b-col>
        <b-col>
          <span>
            {{ lang("t_minOccurrence") }}
          </span>
        </b-col>
        <b-col>
          <span>
            {{ lang("t_maxOccurrence") }}
          </span>
        </b-col>

        <b-col>
          <span>
            {{ lang("t_inFirstSeconds") }}
          </span>
        </b-col>
        <b-col>
          <span>
            {{ lang("t_inLastSeconds") }}
          </span>
        </b-col>
        <b-col>
          <span>
            {{ lang("t_speaker") }}
          </span>
        </b-col>

        <b-col>
          <span>
            {{ lang("t_reverse") }}
          </span>
        </b-col>
        <b-col>
          <span>
            {{ lang("t_actions") }}
          </span>
        </b-col>
      </b-row>
      <b-row
        v-for="(keyword, index) in selected_rule.keywords"
        :key="index"
        class="mb-1 pb-1 border-bottom"
      >
        <b-col>
          <b-form-input
            v-model="keyword.keyword"
            :placeholder="lang('t_keyWord')"
          />
        </b-col>
        <b-col>
          <b-form-input
            v-model="keyword.min_occurrence"
            type="number"
            :placeholder="lang('t_minOccurrence')"
          />
        </b-col>
        <b-col>
          <b-form-input
            type="number"
            v-model="keyword.max_occurrence"
            :placeholder="lang('t_maxOccurrence')"
          />
        </b-col>

        <b-col>
          <b-form-input
            type="number"
            v-model="keyword.in_first_seconds"
            :placeholder="lang('t_inFirstSeconds')"
          />
        </b-col>
        <b-col>
          <b-form-input
            type="number"
            v-model="keyword.in_last_seconds"
            :placeholder="lang('t_inLastSeconds')"
          />
        </b-col>
        <b-col>
          <v-select
            v-model="keyword.speaker"
            :options="speakers"
            :placeholder="lang('t_speaker')"
            :reduce="(val) => val.internal_name"
            value="internal_name"
            label="display_name"
          ></v-select>
        </b-col>

        <b-col class="d-flex align-items-center justify-content-start">
          <b-form-checkbox v-model="keyword.reverse">{{
            lang("t_reverse")
          }}</b-form-checkbox>
        </b-col>

        <b-col>
          <b-row>
            <b-col>
              <b-button
                @click="clear_keyword(keyword)"
                variant="flat-secondary"
              >
                <feather-icon size="16" icon="DeleteIcon"></feather-icon>
              </b-button>
              <b-button @click="delete_keyword(keyword)" variant="flat-danger">
                <feather-icon size="16" icon="TrashIcon"></feather-icon>
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button @click="add_key_word" variant="primary">
            <feather-icon icon="PlusIcon"></feather-icon>
            {{ lang("t_addRecord") }}</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  BFormTextarea,
  BSpinner,
  VBToggle,
  VBTooltip,
  BCardText,
  BCardFooter,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BFormSelect,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useList from "./useList";
import storeModule from "./storeModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, password } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    // UsersListFilters,
    flatPickr,
    AppTimelineItem,
    AppTimeline,
    BSpinner,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormTextarea,
    vSelect,
    BCardText,
    BCardFooter,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
  },
  directives: { "b-toggle": VBToggle, "b-tooltip": VBTooltip, Ripple },

  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      is_progress: false,
      isEdit: false,
      edit_rule: false,
      currentData: {
        display_name: "",
        is_mail_send_to_agent: false,
        mail: null,
        start_date: null,
        end_date: null,
        queue_groups: [],
        queue: [],
        agent: [],
        direction: [],
        min_duration: null,
        max_duration: null,
        who_hangup: [],
        verdict: [],
        to: "",
        rules: [],
        logic_condition: 'and',
        show_in_dashboard: false,
        active: false,
        audio_file: false,
        speech_text: false,
        notication: false,
      },
      rule_template: {
        title: "",
        logic_condition: 'and',
        in_order: false,
        min_true_count: 0,
        speech_rules: [],
        keywords: [
          {
            keyword: "",
            min_occurrence: 0,
            max_occurrence: 0,
            in_first_seconds: 0,
            in_last_seconds: 0,
            speaker: 'agent',
            reverse: false,
            logic_condition: 'and',
          }
        ],
      },
      keyword_template: {
        keyword: "",
        min_occurrence: 0,
        max_occurrence: 0,
        in_first_seconds: 0,
        in_last_seconds: 0,
        speaker: 'agent',
        reverse: false,
      },
      speakers: [
        { internal_name: 'agent', display_name: globalThis._lang('t_agent') },
        { internal_name: 'customer', display_name: globalThis._lang('t_customer') },
        { internal_name: 'one_of_both', display_name: globalThis._lang('t_oneOfBoth') },
        { internal_name: 'both_of_them', display_name: globalThis._lang('t_bothOfThem') }
      ],
      logic_conditions: [
        { internal_name: 'and', display_name: globalThis._lang('t_and') },
        { internal_name: 'or', display_name: globalThis._lang('t_or') }
      ],

      speech_rules_items: [
        { internal_name: 'overlappingTalkRate', display_name: "Üstüste Konuşma Oranı (Min - Max) [0-100]" },
        { internal_name: 'overlappingTalkDurationSeconds', display_name: "Üstüste Konuşma Süresi (sn) (Min - Max" },
        { internal_name: 'totalOverlappingTalkDurationSeconds', display_name: "Toplam Üstüste Konuşma Süresi (sn) (Min - Max)" },
        { internal_name: 'silenceRate', display_name: "Sessizlik Oranı (Min - Max) [0-100]" },
        { internal_name: 'silenceDurationSeconds', display_name: "Sessizlik Süresi (sn) (Min - Max)" },
        { internal_name: 'totalSilenceDurationSeconds', display_name: "Toplam Sessizlik Süresi (sn) (Min - Max)" },
        { internal_name: 'initialCallSilenceSeconds', display_name: "Çağrı Başında Sessizlik (sn.) (Min - Max)" },
        { internal_name: 'endCallSilenceSeconds', display_name: "Çağrı Sonunda Sessizlik (sn.) (Min - Max)" },
        { internal_name: 'agentTalkRate', display_name: "Temsilci Konuşma Oranı (Min - Max)  [0-100]" },
        { internal_name: 'agentTalkDurationSeconds', display_name: "Temsilci Konuşma Süresi (sn) (Min - Max)" },
        { internal_name: 'customerTalkRate', display_name: "Müşteri Konuşma Oranı (Min - Max) [0-100]" },
        { internal_name: 'customerTalkDurationSeconds', display_name: "Müşteri Konuşma Süresi (sn) (Min - Max)" },
        { internal_name: 'agentSpeechSpeedWordsPerMinute', display_name: "Temsilci Konuşma Hızı (Kelime/Dakika) (Min - Max)" },
        { internal_name: 'customerSpeechSpeedWordsPerMinute', display_name: "Müşteri Konuşma Hızı (Kelime/Dakika) (Min - Max)" },
        { internal_name: 'agentInterruptionCount', display_name: "Temsilci Söz Kesme Sayısı (Min - Max) [0-100]" },
        { internal_name: 'customerInterruptionCount', display_name: "Müşteri Söz Kesme Sayısı (Min - Max) [0-100]" },
        { internal_name: 'agentMonotoneSpeakingDuration', display_name: "Temsilci Monoton Konuşma Süresi (Min - Max)" },
        { internal_name: 'customerMonotoneSpeakingDuration', display_name: "Müşteri Monoton Konuşma Süresi (Min - Max)" },
        { internal_name: 'agentAggressiveSpeakingDuration', display_name: "Temsilci Agresif Konuşma Süresi (Min - Max) " },
        { internal_name: 'customerAggressiveSpeakingDuration', display_name: "Müşteri Agresif Konuşma Süresi (Min - Max)" },
        // { internal_name: 'slang', display_name: "Argo" },
        // { internal_name: 'emotion', display_name: "Duygu" },
      ],
      selected_queue_group: null,
      selected_speech_rule: '',
      searchQuery: "",
      modal_record_details: false,
      modal_rules: false,
      required,
      alphaNum,
      email,
      password,
      queues: [],
      agents: [],
      records: [],
      email_settings: [],
      selected_rule: {},
      selected_category: {},
      tableColumns: [
        {
          key: "display_name",
          label: globalThis._lang("t_displayName"),
          sortable: true,
        },
        {
          key: "rules",
          label: globalThis._lang("t_rule"),
          sortable: true,
        },


        {
          key: "is_mail_send_to_agent",
          label: globalThis._lang("t_isMailSendToAgent"),
          sortable: true,
        },
        {
          key: "show_in_dashboard",
          label: globalThis._lang("t_showInDashboard"),
          sortable: true,
        },
        {
          key: "active",
          label: globalThis._lang("t_active"),
          sortable: true,
        },
        {
          key: "notification",
          label: globalThis._lang("t_notification"),
          sortable: true,
        },
        {
          key: "speech_text",
          label: globalThis._lang("t_speechText"),
          sortable: true,
        },
        {
          key: "audio_file",
          label: globalThis._lang("t_audioFile"),
          sortable: true,
        },


        {
          key: "actions",
          label: '',
          sortable: false,
          thStyle: { width: '5vh' }
        },
      ]
    };
  },
  computed: {
    items() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.records.slice(start, end);
    },

  },
  mounted: function () {
    this.get_records();
    this.get_queues();
    this.get_users();
    this.GetQueueGroup();
    this.GetEMailSettings();
    this.finish_codes = globalThis.finish_codes;
  },
  methods: {
    queue_group_changed() {
      // console.log("queue_group_changed", this.selected_queue_group)
      let queues = [];
      for (const item of this.selected_queue_group) {
        queues = [...queues, ...item.queues.map((e) => e.internal_name)];
      }
      // console.log("queues", queues)
      this.currentData.queue = [];
      this.currentData.queue = Array.from(
        new Set([...this.currentData.queue, ...queues])
      );
      this.get_live_agent();
      this.get_live_queue();
    },
    add_speech_rule() {
      console.log("this.selected_rule", this.selected_rule)
      if (this.selected_speech_rule.hasOwnProperty('internal_name')) {
        this.selected_rule.speech_rules.push({
          internal_name: this.selected_speech_rule.internal_name,
          display_name: this.selected_speech_rule.display_name,
          min_value: null,
          max_value: null,
        });
      }

    },
    clear_keyword(item) {
      item.keyword = "";
      item.min_occurrence = null;
      item.max_occurrence = null;
      item.in_first_seconds = null;
      item.in_last_seconds = null;
      item.speaker = null;
      item.reverse = false;



    },
    delete_keyword(item) {
      this.selected_rule.keywords = this.selected_rule.keywords.filter(e => e != item);
    },
    clear_speech_rule(item) {
      item.min_value = null;
      item.max_value = null;
    },
    delete_speech_rule(item) {
      this.selected_rule.speech_rules = this.selected_rule.speech_rules.filter(e => e != item);
    },
    update_rule(item) {
      console.log("geldi", item)
      this.edit_rule = true;
      this.selected_rule = item;
      this.modal_rules = true;
    },
    delete_rule() {
      this.currentData.rules = this.currentData.rules.filter(e => e != this.selected_rule);
      this.selected_rule = {};
      this.edit_rule = false;
    },
    add_rule() {
      this.selected_rule = JSON.parse(JSON.stringify(this.rule_template));
      this.modal_rules = true;
      this.edit_rule = false;
    },
    save_rule() {
      if (!this.edit_rule) {
        this.currentData.rules.push(this.selected_rule);
      }
      this.selected_rule = {};
      this.edit_rule = false;
    },

    add_key_word() {
      this.selected_rule.keywords.push({ ...this.keyword_template });
    },
    GetEMailSettings: async function () {
      var response = (await this.$http_in.get(`/spv/v1/EMailSettings`)).data;
      this.email_settings = response;
    },
    get_users: async function () {
      var response = (await this.$http_in.get(`report/v1/User`)).data;

      this.agents = response;
    },
    get_queues: async function () {
      var response = (await this.$http_in.get(`spv/v1/Queue`)).data;
      // console.log('queues', response);
      this.queues = [];
      let p_queues = globalThis.permissions["queue"];
      for (const item of response) {
        if (p_queues.includes(item.internal_name)) {
          this.queues.push(item);
        }
      }
    },
    reset_rule() {
      this.rule_template = {
        title: "",
        logic_condition: 'and',
        in_order: false,
        min_true_count: 0,
        speech_rules: [],
        keywords: [],
      };
      this.isQuestionEdit = false;
    },
    save_question() {
      if (!this.isQuestionEdit) {
        this.selected_category.questions.push(
          JSON.parse(JSON.stringify(this.rule_template))
        );
      } else {
        // Seçilen soru ile doldurulan bilgiler güncellenecek...!
      }
      this.reset_question();
    },
    add_answer() {
      this.rule_template.answers.push(
        JSON.parse(JSON.stringify(this.answers_template))
      );
    },

    delete_answer(item) {
      this.rule_template.answers = this.rule_template.answers.filter(
        (e) => e != item
      );
    },

    add_category() {
      this.currentData.categories.push({
        title: "",
        description: "",
        min: null,
        max: null,
        questions: [],
      });
    },
    get_records: async function () {
      this.is_progress = true;
      var response = (
        await this.$http_in.get(
          `/spv/v1/KeywordCategory/${this.currentPage}/${this.perPage}`
        )
      ).data;
      console.log("resp", response);
      this.records = response.result;
      this.totalUsers = response.count;
      this.is_progress = false;
    },
    get_records_by_phone: async function () {
      if (this.searchQuery == "") {
        this.get_records();
        return;
      }
      this.is_progress = true;

      var response = (
        await this.$http_in.get(`/spv/v1/KeywordCategory/${this.searchQuery}`)
      ).data;
      console.log("resp", response);
      this.records = response.result;
      this.totalUsers = response.count;
      this.is_progress = false;
    },
    check_data() {
      if (this.currentData.display_name == "") {
        return true;
      }
      return false;
    },
    GetQueueGroup: async function () {
      var response = (await this.$http_in.get(`/spv/v1/QueueGroup`)).data;
      // console.log('queues', response);
      // let p_queues = globalThis.permissions["queue"]

      this.queue_groups = response;
      // for (const item of response) {
      //   if (p_queues.includes(item.internal_name)) {
      //     this.queues.push(item);
      //     this.selected_queues.push(item.internal_name);
      //   }
      // }
    },
    add_record: async function () {
      if (this.check_data()) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }
      this.is_progress = true;

      var response;
      if (!this.isEdit) {
        response = (
          await this.$http_in.post(`/spv/v1/KeywordCategory`, {
            data: this.currentData,
          })
        ).data;
      } else {
        response = (
          await this.$http_in.put(`/spv/v1/KeywordCategory`, {
            data: this.currentData,
          })
        ).data;
      }
      this.is_progress = false;

      if (response && response.acknowledged) {

        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      this.reset_record();
      this.get_records();
    },



    delete_record: async function () {
      this.is_progress = true;

      var response = (
        await this.$http_in.delete(
          `/spv/v1/KeywordCategory/${this.currentData._id}`
        )
      ).data;
      this.is_progress = false;

      if (response != null || response != undefined) {
        this.$swal({
          icon: "success",
          title: this.lang("t_processSuccess"),
          text: this.lang("t_customersHaveBeenSuccessfullyDeleted"),
          confirmButtonText: this.lang("t_ok"),
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }

      this.reset_record();
      this.get_records();
    },
    reset_record() {
      this.isEdit = false;
      this.currentData = {
        display_name: "",
        is_mail_send_to_agent: false,
        mail: null,
        start_date: null,
        end_date: null,
        queue_groups: [],
        queue: [],
        agent: [],
        direction: [],
        min_duration: null,
        max_duration: null,
        who_hangup: [],
        verdict: [],
        to: "",
        rules: [],
        logic_condition: 'and',
        show_in_dashboard: false,
        active: false,
        audio_file: false,
        speech_text: false,
        notication: false,
      };
    },
    updateSelected(item) {
      this.currentData = item;
      console.log("item", item);
      this.isEdit = true;
      //this.isAddNewUserSidebarActive = true;
      this.modal_record_details = true;
    },
    updateQuestion(item) {
      this.reset_question();
      this.rule_template = {
        ...item,
        answers: JSON.parse(JSON.stringify([...item.answers])),
      };
      this.isQuestionEdit = true;
    },
    isDeleteCategory(item) {
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteCategory(item);
        }
      });
    },
    deleteCategory(item) {
      this.currentData.categories = this.currentData.categories.filter(
        (e) => e != item
      );
    },
    isDeleteQuestion(item) {
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteQuestion(item);
        }
      });
    },
    confirmText(item) {
      this.currentData = item;
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        console.log("res", result);
        if (result.value) {
          this.delete_record();
        }
      });
    },
    deleteQuestion: async function (item) {
      this.selected_category.questions =
        this.selected_category.questions.filter((e) => e != item);
    },
    deleteRecord: async function () { },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    // const USER_APP_STORE_MODULE_NAME = 'app-user'
    const toast = Vue.$toast;

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const blankUserData = {
      fullName: "",
      username: "",
      email: "",
      role: null,
      currentPlan: null,
      company: "",
      contact: "",
    };

    const resetuserData = () => {
      currentData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    const isAddNewUserSidebarActive = ref(false);

    const extensOptions = [
      { label: "123", value: "123" },
      { label: "456", value: "456" },
      { label: "9999", value: "9999" },
      { label: "654", value: "654" },
    ];
    const projectOptions = [
      { label: "Abc", value: "1" },
      { label: "Proje2", value: "2" },
      { label: "Proje3", value: "3" },
      { label: "Proje4", value: "4" },
    ];
    const profileOptions = [
      { label: "Admin", value: "1" },
      { label: "Profil2", value: "2" },
      { label: "Profil3", value: "3" },
      { label: "Profil4", value: "4" },
    ];

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    var {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      extensOptions,
      projectOptions,
      profileOptions,
      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      toast,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
